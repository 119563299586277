
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { channelColors } from '@/utils/channelColors'

@Component({})
  export default class LeadsByPipelinePie extends BaseChart {
  @Prop() leads: Record<string, any>
  @Prop({ type: Boolean, default: true }) showTitle
  @Prop({ type: String, default: null }) title
  pipelines = []

  // Methods
  getCountByPipeline (pipeline) {
    if (!this.leads?.records) return 0
    return this.leads?.records.filter(lead => lead.pipeline.name === pipeline).length
  }

  getChannelsByPipeline (pipeline) {
    if (!this.leads?.records) {
      return
    }
    const channels = []
    const byPipeline = this.leads.records.filter(lead => lead.pipeline.name === pipeline)
    byPipeline.forEach(lead => {
      if (!channels.map(channel => channel?.name).includes(lead.channel?.name)) {
        channels.push(lead.channel)
      }
    })
    const leadsByChannel = channels.filter(channel => channel !== undefined).map(channel => {
      return {
        color: channelColors?.[channel?.name] || '#737373',
        name: channel?.name,
        description: channel?.description,
        leads: byPipeline.filter(lead => lead.channel?.name === channel?.name),
      }
    })
    return leadsByChannel.sort((prev, next) => next.leads.length - prev.leads.length)
  }

  getLeads () {
    if (!this.leads) return
    const leads = this.leads.records

    if (!leads) return

    let pipelines = []
    leads.forEach(lead => {
      if (!pipelines.map(pipeline => pipeline.name).includes(lead.pipeline.name)) {
        pipelines.push(lead.pipeline)
      }
    })
    pipelines = pipelines.sort((prev, next) => {
      return this.getCountByPipeline(next.name) - this.getCountByPipeline(prev.name)
    })
    this.pipelines = pipelines
    this.series = pipelines.map(pipeline => {
      return leads.filter(lead => {
        return lead.pipeline.name === pipeline.name
      }).length
    })

    this.chartOptions = {
      colors: pipelines.map(pipeline => pipeline.color.split(' ')[0]),
      chart: {
        type: 'pie',
      },
      labels: pipelines.map(pipeline => pipeline.description),
      title: {
        text: this.showTitle ? (this.title ? '' : 'Por negocio') : undefined,
        style: {
          fontFamily: 'Poppins',
        },
      },
      tooltip: {
        y: {
          formatter (val: number) {
            return val + ' negocios'
          },
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '14px',
      },
    }
  }

  // Getters

  get leadsTotal () {
    if (!this.leads?.records) return 0
    return this.leads.records.length
  }

  // Watchers
  @Watch('leads', { immediate: true, deep: true })
  async onDatesChange () {
    this.display = false
    this.getLeads()
    this.display = true
  }
  }
